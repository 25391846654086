<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      size="lg"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm phương thức xét tuyển' : 'Cập nhật phương thức xét tuyển'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên phương thức xét tuyển <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên phương thức xét tuyển"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Nhập tên phương thức xét tuyển"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã phương thức xét tuyển <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã phương thức xét tuyển"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.code"
                  name="code"
                  placeholder="Nhập mã phương thức xét tuyển"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="admissionRoundId">
              <template v-slot:label>
                Đợt xét tuyển <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Đợt xét tuyển"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionRoundId"
                  :options="admissionRounds"
                  :reduce="option => option.value"
                  @input="getTargetAdmissionRound"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="admissionFormTypeId">
              <template v-slot:label>
                Loại phương thức xét tuyển <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại phương thức xét tuyển"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionFormTypeId"
                  :options="dataListAdmissionFormType"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label-for="percentValue">
              <template v-slot:label>
                % chỉ tiêu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="% chỉ tiêu"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.percentValue"
                  name="code"
                  type="number"
                  :state="getElementState(errors)"
                  @input="calculateQuota"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="quota">
              <template v-slot:label>
                Số lượng theo chỉ tiêu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số lượng theo chỉ tiêu"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.quota"
                  name="code"
                  :state="getElementState(errors)"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import FlatpickrLanguages from 'flatpickr/dist/l10n'

export default {
  name: 'Save',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        name: '',
        code: '',
        organizationId: getUser().orgId,
        admissionRoundId: null,
        admissionFormTypeId: null,
        percentValue: 0,
        quota: 0,
        yearId: null,
        target: null,
        status: 1,
        orderNo: 1,
      },
      targetAdmissionRound: null,
      percent: null,
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'admissionRound/statuses',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionFormType: 'factAdmissionFormType/dataListAdmissionFormType',
      admissionRound: 'admissionRound/admissionRoundById',
      percentAdmissionRound: 'admissionRound/percentAdmissionRound',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createAdmissionForm: 'admissionForm/createAdmissionForms',
      updateAdmissionForm: 'admissionForm/updateAdmissionForms',
      getListAdmissionType: 'factAdmissionFormType/getListAdmissionType',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      readAdmissionRoundsById: 'admissionRound/readAdmissionRoundsById',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      await this.getListAdmissionType({})
      if (this.item) {
        this.targetItem = { ...this.item }
      }
    },
    async getTargetAdmissionRound(id) {
      await this.readAdmissionRoundsById({id: id})
      this.targetAdmissionRound = this.admissionRound.target
      this.percent = this.percentAdmissionRound
    },
    calculateQuota() {
      if (this.isCreated) {
        if (this.targetItem.percentValue <= this.percent) {
          const result = (this.targetAdmissionRound * this.targetItem.percentValue) / 100
          this.targetItem.quota = Math.ceil(result)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Phần trăm chỉ tiêu <= ${this.percent}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.readAdmissionRoundsById({
          id: this.targetItem.admissionRoundId,
          admissionFormId: this.item.id})
        this.targetAdmissionRound = this.admissionRound.target
        this.percent = this.percentAdmissionRound
        if (this.targetItem.percentValue <= this.percent) {
          const result = (this.targetAdmissionRound * this.targetItem.percentValue) / 100
          this.targetItem.quota = Math.ceil(result)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Phần trăm chỉ tiêu <= ${this.percent}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        name: '',
        code: '',
        organizationId: getUser().orgId,
        admissionRoundId: null,
        admissionFormTypeId: null,
        percentValue: 0,
        quota: 0,
        openRegisterDate: '',
        closeRegisterDate: '',
        openAdmissionDate: '',
        closeAdmissionDate: '',
        openCheckExaminationDate: '',
        closeCheckExaminationDate: '',
        closeHandleCheckExaminationDate: '',
        proclaimDate: '',
        enrollmentDeadline: '',
        locked: 1,
        yearId: null,
        target: null,
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createAdmissionForm(this.targetItem)
            : await this.updateAdmissionForm(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
